import * as React from 'react';
import { ShowIf } from './helpers';
import { Container } from './grid';
import OVICForm from './ovic-form';
import FormHeader from './form-header';
import FormError from './form-error';
import FormLoading from './form-loading';
import IncidentNotificationFormStep1 from './incident-notification-form-step-1';
import IncidentNotificationFormStep2 from './incident-notification-form-step-2';
import IncidentNotificationFormStep3 from './incident-notification-form-step-3';
import IncidentNotificationFormStep4 from './incident-notification-form-step-4';
import IncidentNotificationFormStep5 from './incident-notification-form-step-5';
import * as styles from './form.module.scss';
const formConfig = require('./incident-notification-form.json');

class IncidentNotificationForm extends OVICForm 
{
  constructor(props) {
    super(props);

    this.state = {
      form: 'incidentnotification',
      step: 1,
      steps: 5,
      files: [],
      filesList: [],
      loading: false,
      percentLoaded: 0,
      formData: {
        organisationName: '',
        organisationContact: '',
        contactPhone: '',
        contactEmail: '',
        whatHappened: '',
        whenDidItHappen: '',
        whenDidOrganisationBecomeAware: '',
        howDidItHappen: '',
        whatStepsToContain: '',
        whatStepsToPrevent: '',
        personalInformation: '',
        personalInformationRisk: '',
        personalInformationNotified: '',
        cirsAssistance: '',
        typeOfInformationPersonal: '',
        typeOfInformationHealth: '',
        typeOfInformationFinancial: '',
        typeOfInformationPolicy: '',
        typeOfInformationLegal: '',
        typeOfInformationOperational: '',
        typeOfInformationCriticalInfrastructure: '',
        typeOfInformationLawEnforcement: '',
        typeOfInformationCrimeStatistics: '',
        typeOfInformationOther: '',
        businessImpactLevel1: '',
        businessImpactLevel2: '',
        businessImpactLevel3: '',
        businessImpactLevel4: '',
        informationFormatHardCopy: '',
        informationFormatElectronic: '',
        informationFormatVerbal: '',
        securityAttributesConfidentiality: '',
        securityAttributesIntegrity: '',
        securityAttributesAvailability: '',
        incidentCausedPeople: '',
        incidentCausedProcess: '',
        incidentCausedTechnology: '',
        incidentCausedControls: '',
        incidentResponsibilityInternal: '',
        incidentResponsibilityAuthorised: '',
        incidentResponsibilityExternal: '',
        incidentResponsibilityOther: '',
        threatTypeAccidental: '',
        threatTypeFailure: '',
        threatTypeMalicious: '',
        threatTypeNAtural: '',
        incidentClosed: '',
        incidentRecorded: '',
      },
      formErrors: {
      },
    }
  }

  render() {
    return (
      <div 
        className={styles.root}
        ref={this.rootRef}>
        <FormHeader
          title={formConfig.title}
          steps={formConfig.steps}
          step={this.state.step}
        />
        <Container>
          <IncidentNotificationFormStep1 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <IncidentNotificationFormStep2 
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <IncidentNotificationFormStep3
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <IncidentNotificationFormStep4
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
          <IncidentNotificationFormStep5
            onInput={this.handleInput}
            onChange={this.handleChange}
            onSubmit={this.handleSubmit}
            onPrevious={this.handlePrevious}
            onUpdateFiles={this.handleUpdateFiles}
            formData={this.state.formData}
            formErrors={this.state.showErrors ? this.state.formErrors : false}
          />
        </Container>
        <ShowIf cond={this.state.loading}>
          <FormLoading 
            percent={this.state.percentLoaded}
          />
        </ShowIf>
        <ShowIf cond={this.state.submitError}>
          <FormError>
            <p>This form could no be submitted due to a system error.</p>
          </FormError>
        </ShowIf>
      </div>
    )
  }
}

export default IncidentNotificationForm;
