import * as React from 'react';
import { Row, Cell } from './grid';
import { Button } from './form';
import FormIntro from './form-intro';
import * as styles from './form.module.scss';

const IncidentNotificationFormStep1 = (props) => {
  const {
    onSubmit,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormIntro>
        <Row>
          <Cell className="large-7">
            <h1>Information security and privacy incident notification form</h1>
            <h2>What you should know before using this form</h2>
            <p>Organisations that are subject to the Victorian Protective Data Security Standards (<strong>VPDSS</strong>) under Part 4 of the <em>Privacy and Data Protection Act 2014 (Vic)</em> (<strong>PDP Act</strong>) must notify OVIC of certain information security incidents.</p>
            <p>In addition, organisations that are subject to Part 3 of the PDP Act are encouraged to notify OVIC of incidents involving personal information that could cause harm to affected individuals.</p>
            <p>Any organisation that is subject to the PDP Act can use this form to report incidents to OVIC, whether voluntarily or by obligation.</p>
            <h2>How will the information I provide be used?</h2>
            <p>We use the information you provide to help us manage information security and privacy incident notifications. This includes confirming that we received your notification and contacting you to discuss the incident if we need to.</p>
            <p>We may also send your information to the Victorian Government Cyber Incident Response Service, if you want us to.</p>
            <p>Information you enter onto this form is stored locally, in your browser, until you submit it. If you do not submit the form, the information you enter will stay stored locally in your browser until you clear the browser cache or delete any information you entered onto the form.</p>
            <Button 
              text="Start the form"
            />
          </Cell>
          <Cell className="large-5">
            <Button 
              text="Start the form"
              className="show-for-large"
            />
            <p><em>This form will take 15 - 30 minutes to complete.</em></p>
            <p><em>You will be emailed a copy of your submission.</em></p>
            <h2>Need help?</h2>
            <p>Contact us by phone on <strong>1300 006 842</strong> or email at <a href="mailto:security@ovic.vic.gov.au">security@ovic.vic.gov.au</a>.</p>
            <h2>More information?</h2>
            <p>You can find more information about OVIC's <a href="https://ovic.vic.gov.au/data-protection/ovic-information-security-incident-notification-scheme/" target="_blank" rel="noopener noreferrer">incident notification process on our website.</a></p>
            <Button 
              text="Start the form"
              className="hide-for-large"
            />
          </Cell>
        </Row>
      </FormIntro>
    </form>
  )
}

export default IncidentNotificationFormStep1;
